<template>
  <el-dialog
    :visible.sync="visible"
    title="医生信息"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :modal-append-to-body="false"
  >
    <el-form
      :model="ruleForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmitHandle"
      label-width="150px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="医生姓名" prop="doctorName">
            <el-input v-model="ruleForm.doctorName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="医生科室" prop="doctorDepartment">
            <el-input v-model="ruleForm.doctorDepartment"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="点击上传头像" prop="doctorAvatar">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="bannerSuccess"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
            >
              <img
                v-if="ruleForm.doctorAvatar"
                :src="ruleForm.doctorAvatar"
                class="avatar"
              />

              <div v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="医生简介" prop="doctorIntro">
            <el-input v-model="ruleForm.doctorIntro"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="医生职称" prop="doctorTitle">
            <el-input v-model="ruleForm.doctorTitle"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="从业年限" prop="years">
            <el-input v-model="ruleForm.years" type="number"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button type="primary" @click="dataFormSubmitHandle">{{
        $t('confirm')
      }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// import debounce from 'lodash/debounce'
export default {
  name: 'addInfo',
  props: {},
  data() {
    return {
      visible: false,
      ruleForm: {
        doctorId: '',
        doctorName: '',
        doctorDepartment: '', // 医生科室
        doctorIntro: '', // 医生简介
        doctorTitle: '', // 医生职称
        room: '', // 房间号
        doctorAvatar: '', // 医生头像
        years: '',
      },
      action: `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`,
    };
  },
  computed: {
    dataRule() {
      return {
        doctorName: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        doctorDepartment: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        doctorAvatar: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        doctorIntro: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        doctorTitle: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        years: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  methods: {
    init() {
      this.visible = true;
    },
    bannerSuccess(res) {
      this.$set(this.ruleForm, 'doctorAvatar', this.initImg(res));

      // this.formData.productPicture = URL.createObjectURL(file.raw);
    },
    initImg(res) {
      const { code = 99, data = {}, msg = '网络异常' } = res;
      if (code != 0) {
        this.$message.error(msg);
        return false;
      }
      return data.src;
    },
    // 表单提交
    dataFormSubmitHandle() {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false;
        }
        let item = JSON.parse(JSON.stringify(this.ruleForm));
        this.$emit('docInfoSave', item);
        this.$refs['dataForm'].resetFields();
        this.visible = false;
      });
    },
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.red {
  color: red;
  font-size: 16px;
}
.v-modal,
.el-dialog__wrapper {
  position: absolute;
}
</style>
